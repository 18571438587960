<template>
  <v-container class="mt-5 ml-2" fluid >
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Kpi por departamento
            <v-spacer></v-spacer>

            <v-btn 
            	color="primary"
            	@click="initialize4(), getNI()" 
            	small
            	tile
            >
          		Consultar
          	</v-btn>
          </v-card-title>


          <!-- Contenido de la pagina -->
          <v-card-text>

          	<!-- Filtros -->
          	<v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

          	<v-row>
          		<v-col cols="12">
		            <v-row>
		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="rh.rotacion <= 3 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ rh.rotacion }} %
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 3%
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Recursos Humanos
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    % DE ROTACION
		            		  </v-card-text>

		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  	@click="dialogRh.estatus = true"
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="rhAuxi.tiempogeneral <= 3 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ rhAuxi.tiempogeneral }} %
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Recursos Humanos
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    % DE VACANTES CUBIERTAS
		            		  </v-card-text>

		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  	@click="dialogRh.estatus = true"
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ totalFastM.inscritos }}
		            		    	</v-card>
		            		    	Fast
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  	<div> 
		            		    	<v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ totalInbiM.inscritos }}
		            		    	</v-card>
		            		    	Inbi
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 
		            		  </v-card-text>

		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Comercial
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    VENTAS NUEVAS TOTALES
		            		  </v-card-text>

		            			<v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  	@click="dialogComercial.estatus = true"
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card color="green darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		3.64
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 3
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    TI
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    ÍNDICE DE SATISFACCIÓN DEL USUARIO
		            		  </v-card-text>

		            			<v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  	@click="dialogTI.estatus = true"
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="finanzas.cumplio ? finanzas.cumplio >= 1 ? 'green' : 'red darken-2' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ finanzas.cumplio ? finanzas.cumplio >= 1 ? 'SI' : 'NO' : 'NO' }}
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
											</v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 1
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Finanzas
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    ÍNDICE DE EFICIENCIA FINANCIERA
		            		  </v-card-text>

		            			<v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  	@click="dialogFinanzas.estatus = true"
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>


		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ totalFast }}
		            		    	</v-card>
		            		    	Fast
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  	<div> 
		            		    	<v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ totalInbi }}
		            		    	</v-card>
		            		    	Inbi
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Operaciones - NI
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    VENTAS TOTALES NI - RI
		            		  </v-card-text>
		            			
		            		</v-card>
		            	</v-col>


		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ totalFastRI }}
		            		    	</v-card>
		            		    	Fast
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  	<div> 
		            		    	<v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ totalInbiRI }}
		            		    	</v-card>
		            		    	Inbi
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Operaciones - RI
		            		  </v-card-text>

		            		  <v-card-text class="black--text pt-0">
		            		    VENTAS TOTALES RI
		            		  </v-card-text>

		            			<v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  	@click="dialogOperaciones.estatus = true"
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            </v-row>
          		</v-col>
          	</v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- ZONA DE DESGLOSES -->

    <!-- RH -->
    <JefeRecursos   
    	v-if="dialogRh.estatus"
    	:dialogRh="dialogRh"
    	:rh="rh"
    	:cicloInf="cicloInf"
			:fechaini="fechaini"
			:fechafin="fechafin"
    />

    <!-- COMERCIAL -->
    <JefeComercial   
    	v-if="dialogComercial.estatus"
    	:dialogComercial="dialogComercial"
    	:totalFastM="totalFastM"
    	:totalInbiM="totalInbiM"
    	:cicloInf="cicloInf"
			:fechaini="fechaini"
			:fechafin="fechafin"
    />

    <!-- Finanzas -->
    <JefeFinanzas   
    	v-if="dialogFinanzas.estatus"
    	:dialogFinanzas="dialogFinanzas"
    	:finanzas="finanzas"
    	:cicloInf="cicloInf"
			:fechaini="fechaini"
			:fechafin="fechafin"
    />

    <!-- TI -->
    <JefeTI   
    	v-if="dialogTI.estatus"
    	:dialogTI="dialogTI"
    	:finanzas="finanzas"
    	:cicloInf="cicloInf"
			:fechaini="fechaini"
			:fechafin="fechafin"
    />

    <!-- TI -->
    <JefeOperaciones   
    	v-if="dialogOperaciones.estatus"
    	:dialogOperaciones="dialogOperaciones"
    	:finanzas="finanzas"
    	:cicloInf="cicloInf"
			:fechaini="fechaini"
			:fechafin="fechafin"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  // COMPONENTES PARA EL DESGLOSE
  import JefeRecursos          from '@/components/kpi/JefeRecursos'
  import JefeComercial         from '@/components/kpi/JefeComercial'
  import JefeFinanzas          from '@/components/kpi/JefeFinanzas'
  import JefeTI                from '@/components/kpi/JefeTI'
  import JefeOperaciones       from '@/components/kpi/JefeOperaciones'

  import XLSX from 'xlsx'

  export default {

    components:{
      Alerta,
      carga,
      JefeRecursos,
      JefeComercial,
      JefeFinanzas,
      JefeTI,
      JefeOperaciones,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


			ciclos:[],
      cicloInf:null,
      cicloSup:null,

      dialogAlumnos:false,

      headers: [
        { text: 'Vendedora'    , value: 'vendedora'  },
        { text: 'I completo'   , value: 'completos'  },
      ],

      desserts: [],

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      totalInbi:0,
      totalFast:0,

      headers2: [
        { text: 'Vendedora'       , value: 'nombre_completo'       },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
      ],

      ciclo: null,

      rh: { },
      totalFast: 0,
			totalInbi: 0,
			finanzas:{},
			totalFastM: 0,
			totalInbiM: 0,

			dialogRh:{
				estatus: false
			},
			dialogComercial:{
				estatus: false
			},
			dialogFinanzas:{
				estatus: false
			},
			dialogTI:{
				estatus: false
			},
			rhAuxi:{},

			totalFastRI: 1.3,
			totalInbiRI: 1.8,

			dialogOperaciones:{
				estatus: false
			}


    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }

          this.fechaini = null
          this.fechafin = null
        }
      }
    },

    async created () {
      await this.getCiclos()
      await this.initialize4()
      await this.getNI()
    },

    methods: {

    	getCiclos () {
        this.cargar    = true
        this.ciclos    = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	async initialize4(){
    		await this.initialize()
    		await this.initialize2()
    	},

    	initialize () {
        this.cargar    = true
        var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('kpi.departamento', payload ).then(response=>{

          this.rh = response.data.rh 
          this.finanzas = response.data.finanzas 

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize2 () {
        this.cargar    = true
        var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo    : this.fechafin, 
        }

        this.$http.post('kpi.departamento.auxiliar', payload ).then(response=>{

          this.rhAuxi = response.data.rh 

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getNI(){
      	var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
          // Guardar los datos 
					this.totalFast        = response.data.totalFast 
					this.totalInbi        = response.data.totalInbi
					this.totalFastM       = response.data.merca.find( el => el.escuela == 2 )
					this.totalInbiM       = response.data.merca.find( el => el.escuela == 1 )

          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },


      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

    },
  }
</script>